<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <b-form-group
              id="input-group-code-brand"
              label="Код марки"
              label-for="input-code-brand"
            >
              <b-form-input
                id="input-code-brand"
                v-model.trim="f.code_brand"
                placeholder="Код марки"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-brand"
              label="Марка"
              label-for="input-brand"
            >
              <b-form-input
                id="input-brand"
                v-model.trim="f.brand"
                placeholder="Марка"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-code-model"
              label="Код модели"
              label-for="input-code-model"
            >
              <b-form-input
                id="input-code-model"
                v-model.trim="f.code_model"
                placeholder="Код модели"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input--name"
              label="Модель"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model.trim="f.model"
                placeholder="Модель"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <b-form-group
              id="input-group-code-body"
              label="Код кузова"
              label-for="input-code-body"
            >
              <b-form-input
                id="input-code-body"
                v-model.trim="f.code_body"
                placeholder="Код кузова"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-body"
              label="Кузов"
              label-for="input-body"
            >
              <b-form-input
                id="input-body"
                v-model.trim="f.body"
                placeholder="Кузов"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-color"
              label="Цвет"
              label-for="input-color"
            >
              <b-form-input
                id="input-color"
                v-model.trim="f.color"
                placeholder="Цвет"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from './FiltersMixin';
export default {
  mixins: [FiltersMixin],
  data() {
    return {
      f: {
        code_brand: this.$route.query?.code_brand,
        brand: this.$route.query?.brand,
        code_model: this.$route.query?.code_model,
        model: this.$route.query?.model,
        code_body: this.$route.query?.code_body,
        body: this.$route.query?.body,
        color: this.$route.query?.color
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
